import React, { Component } from "react";

import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import notification from 'antd/lib/notification';
import ReactGA from 'react-ga';
import { getSocket } from '../../../socket';

import Queue from './Queue';
import Core from '../../../Core';
import styles from "./index.scss";

export default class QuestionBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        this.form = React.createRef();
        this.handleFinish = this.handleFinish.bind(this);
    }

    componentDidMount() {
      const that = this;
      getSocket().on('newQuestionAck', (msg) => {
          console.log(msg);
          that.handleNewQuestionAck(msg);
      });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleFinish(values) {
      this.setState({ loading: true });
      const fail = () => {
          this.setState({ loading: false });
          notification["error"]({
              message: 'Questão',
              description: 'Não foi possível submeter a tua questão, tente mais tarde.',
          });
          ReactGA.event({
              category: Core.data().config.site_title,
              action: `Q&A`,
              label: `Unsuccessful`
          });
      };
      if (getSocket() != null) {
          getSocket().emit("newQuestion", {
              participant_uid: this.props.data.login.uid,
              question: values.question
          });
          this.form.current.resetFields();
          this.setState({ voteLoading: false, loading: false });
      }
    }

    handleNewQuestionAck(msg) {
      if (msg && msg.result == true) {
          notification.success({
              message: 'Questão',
              description: 'A tua questão foi submetida com sucesso.',
              top: 200
          });
          ReactGA.event({
              category: Core.data().config.site_title,
              action: `Q&A`,
              label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
          });
      } else {
          console.log("Response of alert submit is not JSON!");
      }
    }


    render() {
        const { loading } = this.state;
        return (
            <div className="question-box">
                <div className="question-box__title">
                    <h3>Q&A</h3>
                    <hr/>
                </div>
                <div className="question-box-queue-container">
                    <Queue />
                    <Form className="newQuestion" ref={this.form} layout="vertical" onFinish={this.handleFinish}>
                        <Row>
                            <Col span={18}>
                                <Form.Item className="alertQuestion" name="question" rules={[
                                    {
                                        required: true,
                                        message: 'Por favor preencha a sua questão.'
                                    }
                                ]}>
                                <Input.TextArea className="inputQuestion" placeholder="A sua questão" autoSize />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Spin spinning={loading}>
                                <Button className="buttonSend" id="questionSend"  type="primary" htmlType="submit" loading={loading}>
                                    Enviar
                                </Button>
                                </Spin>
                            </Col>
                        </Row>
                    </Form>
                </div>
                </div>
        );
    }
}
