import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { Typography } from 'antd';

import Speakers from '../../containers/Speakers';
import Core from "../../Core.js";

import './index.scss';

const { Title } = Typography;

export default class SpeakersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectLogin: false,
            data: [],
        };
    }

    componentDidMount() {
        if (Core.data().config.private == true && Core.data().login == null) {
            this.setState({ redirectLogin: true });
        } else {
            this.loadIntroduction();
        }

    }

    loadIntroduction() {
        this.setState({ loading: true });
        Core.apiData(`pages/speakers.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    this.setState({
                        data: data.json,
                    });
                } else {
                    this.setState({ error: true, loading: false });
                    console.log("Schedule introduction is not a JSON!");
                }
            },
            onFail: (data) => {
                this.setState({ error: true, loading: false });
                console.log("Schedule introduction failed.", data);
            }
        });
    }

    render() {
        const { redirectLogin } = this.state;

        if (redirectLogin) {
            window.location.assign("/login");
        }

        return (
            <div className="speakers">
                <img className="produto" src="/data/images/produto.png" />
                <div className="content-title">
                    <Title>Oradores</Title>
                </div>
                <div className="content-body">
                    <Speakers display={false} />
                </div>
            </div>
        );
    }
}
