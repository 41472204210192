import React, { Component } from "react";

import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Spin from 'antd/lib/spin';

import notification from 'antd/lib/notification';
import Queue from './Queue';
import ReactGA from 'react-ga';

import Core from '../../../Core';
import styles from "./index.scss";


export default class ChatBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            emojiPicker: false,
            text: ''

        };
        this.form = React.createRef();
        this.queue = React.createRef();
        this.chatInput = React.createRef();
        this.handleFinish = this.handleFinish.bind(this);


       // this.isBottom = this.isBottom.bind(this);
        //this.scrollTarget = React.createRef(HTMLDivElement);

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleFinish(values) {
        this.setState({ loading: true });
        this.queue.current.newMessage(values.message);
        this.form.current.resetFields();
        this.chatInput.current.focus();
        const fail = () => {
            this.setState({ loading: false });
            this.queue.current.updateChat();
            notification["error"]({
                message: 'Mensagem',
                description: 'Não foi possível enviar a sua mensagem, tente mais tarde.',
            });
            ReactGA.event({
              category: Core.data().config.site_title,
              action: `Chat`,
              label: `Unsuccessful`
            });
        };


        Core.apiService("chat/send", {
            data: {
                hash: this.props.data.hash,
                message: values.message
            },
            onSuccess: (data) => {
                if (data.json && data.json.result) {
                    this.queue.current.updateChat();
                    this.setState({ loading: false });
                    ReactGA.event({
                      category: Core.data().config.site_title,
                      action: `Chat`,
                      label: `${Core.data().login.name} (${Core.data().login.hid.substring(0,3)})`
                    });
                } else {
                    console.log("Response of alert submit is not JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                console.log("Message create failed.", data);
                fail();
            }
        });
    }

    render() {
        const { loading, text } = this.state;

        return (
          <div className="chat-box">
              <div className="chat-box__title">
                  <div className="chat-box__sessions">
                      <img src="/images/icon_people_white.png"/><br/>
                      <span>{Core.data().sessions}</span>
                  </div>
                  <h3>
                      Chat
                  </h3>
                  <hr/>
              </div>
              <Queue ref= { this.queue }/>
              <Form className="newMessage" ref={ this.form } layout="vertical" onFinish={this.handleFinish}>
                  <Row>
                      <Col span={18}>
                          <Form.Item className="alertMessage" name="message" rules={[
                              {
                                  required: true,
                                  message: 'Sem mensagem...'
                              }
                          ]}>
                              <Input.TextArea ref={this.chatInput} autoSize={{ minRows: 1, maxRows: 2 }} className="inputMessage" placeholder="Mensagem..."  onPressEnter = {(e) => {this.sendMsg(e)}} />
                          </Form.Item>
                      </Col>
                      <Col span={6}>
                          <Button className="buttonSend" id="chatSend" type="primary" htmlType="submit" disabled={ loading }>
                              Enviar
                          </Button>
                      </Col>
                  </Row>
              </Form>
          </div>
        );
    }
}
