import React, { Component } from "react";

import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Radio from 'antd/lib/radio';
import Popconfirm from 'antd/lib/popconfirm';
import { Row, Col } from 'antd';
import notification from 'antd/lib/notification';
import ReactGA from 'react-ga';

import Core from "../../../Core";
import "./index.scss";

export default class LivePoll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: { options: [] },
            submitting: false,
            optionUid: '',
            voteLoading: false,

        };
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleVote = this.handleVote.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    loadData() {
        const delay = 3000;
        const fail = () => {
            this.setState({ data: { options: [] } });
            window.setTimeout(() => this.loadData(), delay);
        };
        Core.apiData(`live-poll/${Core.data().arena}.json`, {
            data: {
                hash: Core.data().hash
            },
            onSuccess: (data) => {
                if (typeof data == 'undefined') {
                    this.setState({ data: { options: [] } });
                    window.setTimeout(() => this.loadData(), delay);
                } else if (data.json) {
                    this.setState({ data: data.json });
                    window.setTimeout(() => this.loadData(), delay);
                } else {
                    this.setState({ data: { options: [] } });
                    console.log("Voting data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                console.log("Voting data failed.", data);
                fail();
            }
        });
    }

    handleOptionChange(e) {
        this.setState({ optionUid: e.target.value });
    }

    handleVote() {
        this.setState({ voteLoading: true });
        const fail = () => {
            notification.error({
                message: 'Voto',
                description: 'Não pode ser submetido, tente mais tarde.',
            });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `LivePoll`,
                label: `Unsuccessful`
            });
        };

        const optionUid = this.state.optionUid;
        Core.apiService(`live-poll/vote`, {
            data: {
                hash: this.props.data.hash,
                live_poll_uid: this.state.data.uid,
                live_poll_option_uid: this.state.optionUid
            },
            onSuccess: (data) => {
                if (data.json.result == true) {
                    console.log("LIVE POLL VOTE props DATA");
                    console.log(this.props.data);
                    sessionStorage.setItem("live-poll-" + this.state.data.uid + "-" + this.props.data.login.hid, optionUid);
                    this.setState({ data: { options: [] } });
                    notification.success({
                        message: 'Voto',
                        description: 'O seu voto foi realizado com sucesso.'
                    });
                    this.loadData();
                    ReactGA.event({
                        category: Core.data().config.site_title,
                        action: `LivePoll`,
                        label: `${Core.data().login.name} (${Core.data().login.hid.substring(0, 3)})`
                    });
                } else {
                    console.log("Vote data is not a JSON!");
                    fail();
                }
                this.setState({ voteLoading: false });
            },
            onFail: (data) => {
                console.log("Failed.", data);
                fail();
                this.setState({ voteLoading: false });
            }
        });
    }

    render() {
        const { data } = this.state;
        if (!data.uid || Core.data().session_uid == '') {
            if (Core.data().config.live_poll_waiting != '') {
                return (
                    <Row gutter={20} className="player__container__live-poll">
                        <Col xs={24} sm={24} md={24} lg={Core.data().config.chat == true || Core.data().config.questions == true ? 16 : 24} xl={Core.data().config.chat == true || Core.data().config.questions == true ? 18 : 24}>
                            <div className="live-poll__waiting">
                                <h3>{Core.data().config.live_poll_waiting}</h3>
                            </div>
                        </Col>
                    </Row>);
            }
            return <></>;
        }
        const optionVoted = sessionStorage.getItem("live-poll-" + data.uid + "-" + this.props.data.login.hid, this.state.optionUid);
        const radios = [];
        let radioGroupExtra = {};
        if (optionVoted) {
            radioGroupExtra = { value: optionVoted };
        } else if (Core.data().live_poll_voted[data.uid]) {
            radioGroupExtra = { value: Core.data().live_poll_voted[data.uid] };
        }
        for (let option of data.options) {
            let percents = 0;
            if (option.total > 0 && Core.data().config.live_poll_percent) {
                percents = parseInt(Math.round(100 * option.total / this.state.data.total, 10));
            }
            radios.push(
                <div className="live-poll__option">
                    <div className={"live-poll__option-radio" + (Core.data().config.live_poll_percent == false ? " live-poll__option-radio-percents-off" : "")}>
                        <Radio key={option.uid} value={option.uid} disabled={this.state.data.voted}>{option.description}</Radio>
                    </div>
                    {Core.data().config.live_poll_percent == false ? null :
                        <div className="live-poll__option-total">
                            {percents}%
                   </div>}
                </div>
            );
        }
        const questionLines = [];
        for (const line of this.state.data.question.split('\n')) {
            questionLines.push(<p>{line}</p>);
        }

        return (
            (questionLines.length > 0 ?
                <Row gutter={20} className="player__container__live-poll">
                    <Col xs={24} sm={24} md={24} lg={Core.data().config.chat == true || Core.data().config.questions == true ? 16 : 24} xl={Core.data().config.chat == true || Core.data().config.questions == true ? 18 : 24}>
                        <div className="live-poll">
                            <div className="live-poll__question">
                                <div className={"live-poll__question-info" + (Core.data().config.live_poll_percent == false ? " live-poll__question-info-percents-off" : "")}>
                                    <h3>
                                        {questionLines}
                                    </h3>
                                    <h6>Selecione a sua opção</h6>
                                </div>
                                <div className="live-poll__question-total">
                                    <img src="/images/icon_people_black.png" /><br />
                                    <span>{this.state.data.total > 0 ? `${this.state.data.total} voto` + (this.state.data.total > 1 ? 's' : '') : 'Sem Votos'}</span>
                                </div>
                            </div>
                            <Radio.Group name="livePollUid" onChange={this.handleOptionChange} {...radioGroupExtra}>
                                {radios}
                            </Radio.Group>
                            <div className={"live-poll__footer" + (Core.data().config.live_poll_percent == false ? " live-poll__footer-percents-off" : "")}>
                                {typeof radioGroupExtra.value == 'undefined' ?
                                    <Button type="primary" className="live-poll__button" disabled={this.state.optionUid == ''} loading={this.state.voteLoading} onClick={this.handleVote}>
                                        Submeter
                                </Button> : <p>Obrigado pelo seu voto.</p>}
                            </div>
                        </div >
                    </Col>
                </Row>
                : null)
        );
    }
}
