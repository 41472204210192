
import React, { Component } from 'react';
import { Layout } from 'antd';
import styles from './Footer.scss';
import Core from '../Core';
import {
    Link
} from "react-router-dom";


const { Footer } = Layout;

class MainFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.tableData) {
            return {
                tableData: nextProps.tableData
            }
        }
        return null;
    }

    render() {
        return (
            <Footer className="footer">
                {
                    <p>
                        <a href="https://www.aptababy.com.pt/privacy-policy">Política de Privacidade</a>
                        &nbsp; · &nbsp;
                        <a href="/termos-e-condicoes">Termos e Condições</a>
                    </p>
                }
                {Core.data().config.branding == true ?
                    <p>&copy; 2020 - <a class="highlight-color" href="https://www.yal.pt/" target="_blank">YouAreLive</a> - Plataforma de transmissão de eventos online. Todos os direitos reservados.</p>
                    : null}
            </Footer>
        )
    }
}

export default MainFooter;
