import React, { Component } from 'react';
import { Typography } from 'antd';

import Core from "../../Core.js";

import './index.scss';

const { Title } = Typography;

export default class TermsConditionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="terms-condition">
                <div className="content-title">
                    <Title>TERMOS E CONDIÇÕES GERAIS DE ACESSO E UTILIZAÇÃO DESTE SITE</Title>
                </div>
                <div className="content-body">
                    <p ><span >I.- Est&aacute; a aceder a um site propriedade da Milupa Comercial &ndash; Comercializa&ccedil;&atilde;o de Produtos Alimentares, S.A., pessoa colectiva n.&ordm; 502 192 828, matriculada na Conservat&oacute;ria do Registo Comercial de Lisboa, sob o mesmo n&uacute;mero, com sede em Av. D. Jo&atilde;o II, n&ordm; 41 &ndash; 3&ordm; Piso, 1990-084 Lisboa, (doravante abreviadamente designada MILUPA). Este site destina-se a ser visitado exclusivamente por Profissionais de Sa&uacute;de (doravante abreviadamente designado por &ldquo;Utilizador&rdquo;) de forma livre e gratuita.&nbsp;</span></p>
                    <p ><span >Entender-se-&aacute; por &ldquo;Utilizador&rdquo; qualquer pessoa singular que aceda ou tenha acedido a este site e declare ser Profissional de Sa&uacute;de, encontrando-se sujeito tanto aos presentes Termos e Condi&ccedil;&otilde;es<strong>&nbsp;</strong>como ao conte&uacute;do dos Avisos Legais, Termos de Responsabilidade, Textos Legais e Pol&iacute;ticas de Privacidade e Prote&ccedil;&atilde;o de Dados Pessoais, se for o caso (doravante denominados conjuntamente como &ldquo;Avisos Legais&rdquo;).&nbsp;</span></p>
                    <p ><span >II.-O Utilizador dever&aacute;, em qualquer situa&ccedil;&atilde;o, utilizar este site e os seus conte&uacute;dos de forma diligente e em conformidade com os presentes Termos e Condi&ccedil;&otilde;es<strong>&nbsp;</strong>e conte&uacute;do dos Avisos Legais, bem como de acordo com a Lei, a moral, a ordem p&uacute;blica e os bons costumes. A MILUPA reserva-se o direito de negar ou retirar o acesso a este site e/ou aos servi&ccedil;os nele contidos, em qualquer momento e sem necessidade de aviso pr&eacute;vio, a qualquer Utilizador que n&atilde;o cumpra o estabelecido nas presentes Condi&ccedil;&otilde;es Gerais, em especial o estabelecido no par&aacute;grafo anterior.&nbsp;</span></p>
                    <p ><span >A MILUPA reserva-se o direito de, a qualquer momento, sem necessidade de aviso pr&eacute;vio e com efeitos imediatos, alterar, adicionar, atualizar ou eliminar, parcial ou totalmente, os presentes Termos e Condi&ccedil;&otilde;es de Utiliza&ccedil;&atilde;o e Politica de Privacidade e demais regulamenta&ccedil;&atilde;o aplic&aacute;vel.</span></p>
                    <p ><span >III.- Os Utilizadores que visitam o Website podem aceder e ler todo o conte&uacute;do contido no mesmo, devendo, para esse efeito, registarem-se no mesmo.&nbsp;</span></p>
                    <p ><span >IV.-A MILUPA reserva-se o direito de modificar ou suprimir, a qualquer momento e sem aviso pr&eacute;vio, os conte&uacute;dos, servi&ccedil;os e informa&ccedil;&otilde;es que se encontram neste site, as quais produzem efeitos imediatos sem necessidade de comunica&ccedil;&atilde;o pr&eacute;via aos Utilizadores. <br />&nbsp;<br />&nbsp;V.-O Utilizador compromete-se a utilizar este site e os seus servi&ccedil;os em conformidade com os presentes Termos e Condi&ccedil;&otilde;es assim como, as disposi&ccedil;&otilde;es legais e/ou regulamentares, que se mostrem aplic&aacute;veis.</span></p>
                    <p ><span >VI.-<span>&nbsp;</span>O Utilizador obriga-se a n&atilde;o utilizar esta p&aacute;gina Web e os servi&ccedil;os disponibilizados para fins il&iacute;citos ou contr&aacute;rios ao estabelecido nos presentes Termos e Condi&ccedil;&otilde;es, ou que sejam lesivos ou possam prejudicar ou direitos e ou interesses de terceiros ou que, de qualquer forma, possam danificar, prejudicar, sobrecarregar ou deteriorar a p&aacute;gina Web e os servi&ccedil;os disponibilizados ou impedir a sua normal utiliza&ccedil;&atilde;o ou uso pelos Utilizadores.</span></p>
                    <p ><span >VII.-O Utilizador dever&aacute; abster-se de obter, e inclusive de tentar obter, informa&ccedil;&otilde;es, mensagens, gr&aacute;ficos, desenhos, ficheiros de som e/ou imagem, fotografias, grava&ccedil;&otilde;es, software e, de uma forma geral, qualquer tipo de material acess&iacute;vel atrav&eacute;s deste site ou dos seus servi&ccedil;os utilizando para isso meios ou procedimentos diferentes dos que, conforme o caso, tenham sido colocados &agrave; disposi&ccedil;&atilde;o para este efeito ou tenham sido indicados atrav&eacute;s deste site. <br />&nbsp;<br />&nbsp;VIII.-N&atilde;o &eacute; permitida a inclus&atilde;o dos conte&uacute;dos deste site em outros portais da Internet, nem em outros meios ou suportes. De igual modo, n&atilde;o &eacute; permitida a reprodu&ccedil;&atilde;o, distribui&ccedil;&atilde;o, comunica&ccedil;&atilde;o p&uacute;blica nem transforma&ccedil;&atilde;o dos referidos conte&uacute;dos deste site, quer seja gratuitamente ou mediante compensa&ccedil;&atilde;o econ&oacute;mica, sob qualquer forma ou suporte. Todos os conte&uacute;dos desta p&aacute;gina Web, como seja, texto, imagens, gr&aacute;ficos, ficheiros, layout, estrutura, entre outros, encontram-se protegidos pelas disposi&ccedil;&otilde;es legais relativas &agrave; propriedade intelectual e, em especial, pelo &acirc;mbito de prote&ccedil;&atilde;o dos Direitos de Autor. Os referidos conte&uacute;dos (que compreendem quaisquer marcas, registadas, livres, not&oacute;rias ou de grande prest&iacute;gio, modelos, sinais, ins&iacute;gnias, desenho, nomes de produtos, de estabelecimento e de servi&ccedil;os, firmas, log&oacute;tipos, sinais distintivos de com&eacute;rcio ou relativos aos regimes jur&iacute;dicos da propriedade industrial exibidos ou utilizados no site) n&atilde;o poder&atilde;o ser assim copiados para uso comercial ou para distribui&ccedil;&atilde;o, nem ser modificados.</span></p>
                    <p ><span >IX.- AVISO LEGAL SOBRE PROPRIEDADE INTELECTUAL E INDUSTRIAL&nbsp;</span></p>
                    <p ><span >S&atilde;o propriedade da MILUPA e encontram-se protegidos pelas leis portuguesa, da Uni&atilde;o Europeia e internacionais, todos os direitos de propriedade intelectual e industrial sobre este site assim como todos os seus conte&uacute;dos, ficando reservada a favor da MILUPA a sua exclusiva titularidade ao n&iacute;vel mundial assim como durante toda a sua vig&ecirc;ncia.&nbsp;</span></p>
                    <p ><span >Encontram-se compreendidos entre os referidos direitos, a t&iacute;tulo meramente exemplificativo, quaisquer direitos de propriedade intelectual e industrial sobre textos, imagens, desenhos, combina&ccedil;&otilde;es de cores, ficheiro de &aacute;udio e/ou de v&iacute;deo, ficheiros de software, bot&otilde;es, marcas, logotipos, slogans e desenhos, programas inform&aacute;ticos e seus elementos (c&oacute;digos fonte, interfaces, aplica&ccedil;&otilde;es, desenvolvimentos do sistema, entre outros), bem como sobre a estrutura, desenho, sele&ccedil;&atilde;o, ordena&ccedil;&atilde;o e apresenta&ccedil;&atilde;o de qualquer informa&ccedil;&atilde;o e/ou conte&uacute;do neste site. De igual modo, o conte&uacute;do deste site &eacute; considerado um programa inform&aacute;tico a que &eacute; aplic&aacute;vel a legisla&ccedil;&atilde;o portuguesa, da Uni&atilde;o Europeia e internacional em vigor sobre esta mat&eacute;ria.&nbsp;</span></p>
                    <p ><span >S&atilde;o expressamente proibidas, em qualquer caso, a reprodu&ccedil;&atilde;o, c&oacute;pia, comunica&ccedil;&atilde;o p&uacute;blica, distribui&ccedil;&atilde;o, modifica&ccedil;&atilde;o, transforma&ccedil;&atilde;o, supress&atilde;o, manipula&ccedil;&atilde;o e qualquer outra forma de utiliza&ccedil;&atilde;o, com ou sem lucros, de todo ou parte deste site ou qualquer um dos seus conte&uacute;dos, sem a pr&eacute;via e expressa autoriza&ccedil;&atilde;o por escrito da MILUPA.&nbsp;</span></p>
                    <p ><span >A proibi&ccedil;&atilde;o referida no par&aacute;grafo anterior estende-se aos dispositivos t&eacute;cnicos de prote&ccedil;&atilde;o, sinais digitais e quaisquer mecanismos de informa&ccedil;&atilde;o relacionados com os conte&uacute;dos. &Eacute; especialmente proibida a utiliza&ccedil;&atilde;o de informa&ccedil;&atilde;o de qualquer tipo obtida atrav&eacute;s deste site ou de qualquer um dos seus conte&uacute;dos, para fins ou por meios publicit&aacute;rios, promocionais e/ou comerciais de qualquer &iacute;ndole.&nbsp;</span></p>
                    <p ><span >Sem preju&iacute;zo do disposto nos par&aacute;grafos anteriores, determinados conte&uacute;dos deste site pertencem aos respetivos autores e s&atilde;o utilizados neste site em virtude da correspondente licen&ccedil;a ou autoriza&ccedil;&atilde;o dos mesmos.&nbsp;</span></p>
                    <p ><span >Com exce&ccedil;&atilde;o dos dados de car&aacute;cter pessoal que a MILUPA possa recolher de acordo com o estabelecido na sec&ccedil;&atilde;o subsequente dos presentes Termos e Condi&ccedil;&otilde;es</span> <span >e da&nbsp;</span><a href="https://www.aptababy.com.pt/privacy-policy"><span >Pol&iacute;tica de Privacidade aplic&aacute;vel</span></a><span >, quaisquer informa&ccedil;&otilde;es, ideias ou materiais, de qualquer natureza, que sejam fornecidas &agrave; MILUPA pelo Utilizador ser&atilde;o considerados p&uacute;blicos e n&atilde;o confidenciais, e poder&atilde;o ser utilizados livremente e sem qualquer custo pela MILUPA, bem como por qualquer uma das suas sociedades-m&atilde;e ou filiais, nos termos descritos no par&aacute;grafo anterior.&nbsp;</span></p>
                    <p ><span >O Utilizador, ao fornecer as informa&ccedil;&otilde;es ou conte&uacute;dos no sentido e com as consequ&ecirc;ncias descritas nos dois par&aacute;grafos anteriores, garante que n&atilde;o est&aacute; a violar nenhum direito de terceiros, nem a infringir nenhuma disposi&ccedil;&atilde;o legal ou regulamentar, assumindo o Utilizador, de forma plena e exclusiva, qualquer responsabilidade que possa advir do fornecimento das referidas informa&ccedil;&otilde;es ou conte&uacute;dos e assim como as consequ&ecirc;ncias derivadas do referido fornecimento no que diz respeito aos dois par&aacute;grafos anteriores.&nbsp;</span></p>
                    <p ><span >Por conseguinte, a MILUPA n&atilde;o se responsabiliza por poss&iacute;veis infra&ccedil;&otilde;es de direitos de propriedade intelectual e/ou industrial de terceiros causados pelos conte&uacute;dos fornecidos por Utilizadores. Ademais, a MILUPA n&atilde;o poder&aacute; ser responsabilizada por quaisquer danos, perdas ou preju&iacute;zos em pessoas e/ou bens causados pelos m&eacute;todos, teorias, ideias, opini&otilde;es, instru&ccedil;&otilde;es ou recomenda&ccedil;&otilde;es expressas nesses mesmos conte&uacute;dos.&nbsp;</span></p>
                    <p ><span >As marcas registadas, logotipos, ilustra&ccedil;&otilde;es e marcas do servi&ccedil;o (coletivamente as &quot;Marcas&quot;) exibidas neste Website s&atilde;o marcas comerciais registadas ou n&atilde;o registadas. Nada contido neste Website deve ser interpretado como concess&atilde;o de qualquer licen&ccedil;a ou direito de e para as Marcas, sem a nossa expressa autoriza&ccedil;&atilde;o por escrito. A utiliza&ccedil;&atilde;o das Marcas e conte&uacute;do do Website, exceto conforme estabelecido nestes Termos e Condi&ccedil;&otilde;es, &eacute; estritamente proibida. Este &eacute; um aviso de que a MILUPA defender&aacute; os seus direitos de propriedade intelectual sobre este Website e o seu conte&uacute;do em toda a extens&atilde;o da lei, incluindo san&ccedil;&otilde;es criminais.</span></p>
                    <p ><span >AVISO LEGAL SOBRE LIGA&Ccedil;&Otilde;ES E MECANISMOS DE ACESSO A P&Aacute;GINAS WEB</span></p>
                    <p ><span >A MILUPA poder&aacute; disponibilizar ao Utilizador, dispositivos t&eacute;cnicos de liga&ccedil;&atilde;o (tais como links, banners ou bot&otilde;es, entre outros), direct&oacute;rios e ferramentas de busca (doravante &ldquo;Mecanismos de Acesso&rdquo;) que permitam ao Utilizador aceder a p&aacute;ginas Web da MILUPA e ou geridas por terceiros (doravante &ldquo;Liga&ccedil;&otilde;es&rdquo;), que n&atilde;o ser&atilde;o operadas pela MILUPA.</span></p>
                    <p ><span >A instala&ccedil;&atilde;o das referidas Liga&ccedil;&otilde;es nesta p&aacute;gina Web destina-se exclusivamente a permitir ao Utilizador, a busca e o acesso &agrave; informa&ccedil;&atilde;o dispon&iacute;vel na Internet, o que significa que n&atilde;o existe qualquer rela&ccedil;&atilde;o entre a MILUPA e as informa&ccedil;&otilde;es a que se tenha acesso atrav&eacute;s deles ou entre a MILUPA e os titulares dessas p&aacute;ginas web.</span></p>
                    <p ><span >A MILUPA n&atilde;o &eacute; respons&aacute;vel pelo conte&uacute;do de qualquer outro Website, incluindo aqueles que d&ecirc;em acesso ao seu Website ou cujo acesso tenha sido conseguido atrav&eacute;s do nosso Website. A MILUPA n&atilde;o poder&aacute; ser responsabilizada por assuntos relacionados com os Sites ou Links referidos acima.&nbsp;</span></p>
                    <p ><span >A MILUPA n&atilde;o endossa quaisquer Sites vinculados ou os produtos/servi&ccedil;os que s&atilde;o fornecidos nesses Sites. O conte&uacute;do nos sites vinculados podem mudar ao longo do tempo. Recomenda-se a verifica&ccedil;&atilde;o dos termos e pol&iacute;ticas dos Sites vinculados.</span></p>
                    <p ><span >Ao fornecer neste Website um Hyperlink para um Website externo, a MILUPA f&aacute;-lo na boa-f&eacute; de que este contenha ou possa conter informa&ccedil;&otilde;es relevantes adicionais &agrave;s apresentadas no seu pr&oacute;prio Website. A exist&ecirc;ncia desse hyperlink n&atilde;o implica nem pressup&otilde;e a sua revis&atilde;o e aprova&ccedil;&atilde;o por parte da MILUPA - em determinadas inst&acirc;ncias, um hyperlink poder&aacute; lev&aacute;-lo a aceder a um Website que apresente informa&ccedil;&atilde;o contradit&oacute;ria &agrave; apresentada no nosso Website ou a outro pertencente &agrave; MILUPA ou &agrave;s suas subsidi&aacute;rias.</span></p>
                    <p ><span >Tendo em conta o que antecede, a MILUPA afasta toda e qualquer responsabilidade, por quaisquer danos e ou preju&iacute;zos, decorrentes do acesso a tais Liga&ccedil;&otilde;es, em particular, que respeitem:</span></p>
                    <p ><span >1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Ao seu funcionamento, disponibilidade, acessibilidade e ou manuten&ccedil;&atilde;o;</span></p>
                    <p ><span >2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;A manuten&ccedil;&atilde;o dos servi&ccedil;os, conte&uacute;dos, informa&ccedil;&atilde;o, dados, arquivos, produtos e qualquer tipo de material que possa conter;</span></p>
                    <p ><span >3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;A presta&ccedil;&atilde;o ou transmiss&atilde;o dos servi&ccedil;os, conte&uacute;dos, informa&ccedil;&atilde;o, dados, arquivos, produtos e qualquer tipo de material que possa disponibilizar;</span></p>
                    <p ><span >4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;A qualidade, legalidade, fiabilidade e utilidade dos servi&ccedil;os, conte&uacute;dos, informa&ccedil;&atilde;o, dados, arquivos, produtos e qualquer tipo de material que contenha.</span></p>
                    <p ><span >5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;V&iacute;rus ou outros elementos nocivos que podem transmitir estes mecanismos de liga&ccedil;&atilde;o ou p&aacute;ginas interligadas, o que pode causar altera&ccedil;&otilde;es nos sistemas inform&aacute;ticos (software e hardware) ou nos documentos eletr&oacute;nicos e arquivos armazenados.</span></p>
                    <p ><span >A MILUPA n&atilde;o disponibiliza nem comercializa, por si ou por terceiros, produtos e servi&ccedil;os dispon&iacute;veis atrav&eacute;s dos mecanismos de acesso ou Liga&ccedil;&otilde;es. A MILUPA avisa que n&atilde;o controla, aprova, supervisiona ou possui os produtos, servi&ccedil;os, conte&uacute;dos, informa&ccedil;&atilde;o, dados, arquivos, e qualquer tipo de material dispon&iacute;vel atrav&eacute;s dos mecanismos de liga&ccedil;&atilde;o e ou locais referenciados. Por isso, recomenda ao Utilizador a maior prud&ecirc;ncia na avalia&ccedil;&atilde;o e utiliza&ccedil;&atilde;o de tais servi&ccedil;os, informa&ccedil;&atilde;o, dados, arquivos, produtos e qualquer tipo de material disponibilizado pelos mecanismos de acesso ou Liga&ccedil;&otilde;es.</span></p>
                    <p ><span >Por sua vez, o Utilizador, e em geral, todas e quaisquer pessoas que pretendam estabelecer mecanismos de liga&ccedil;&atilde;o a outras p&aacute;ginas web (doravante denominados &ldquo;Web terceiras&rdquo;) devem cumprir as seguintes condi&ccedil;&otilde;es:</span></p>
                    <p ><span >1.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&Eacute; proibido estabelecer qualquer liga&ccedil;&atilde;o com esta p&aacute;gina Web que n&atilde;o contenha a sua p&aacute;gina inicial ou primeira p&aacute;gina;</span></p>
                    <p ><span >2.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;A liga&ccedil;&atilde;o s&oacute; pode permitir o acesso a esta p&aacute;gina web, e a mesma n&atilde;o poder&aacute; ser reproduzida, de qualquer forma;</span></p>
                    <p ><span >3.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&Eacute; proibida a cria&ccedil;&atilde;o de um ambiente de navegador;</span></p>
                    <p ><span >4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Ficam expressamente proibidas todas as declara&ccedil;&otilde;es falsas, imprecisas ou incorretas sobre esta p&aacute;gina web e ou o seu conte&uacute;do e, em particular, as declara&ccedil;&otilde;es expressas ou impl&iacute;citas de que a MILUPA autorizou a liga&ccedil;&atilde;o ou que tenha supervisionado ou assumido de qualquer forma os conte&uacute;dos ou servi&ccedil;os oferecidos ou disponibilizados nas Web terceiras, em que a liga&ccedil;&atilde;o tenha sido estabelecida;</span></p>
                    <p ><span >5.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Com exce&ccedil;&atilde;o dos sinais que fazem parte da liga&ccedil;&atilde;o, a Web terceira est&aacute; proibida de conter qualquer marca, nome comercial, r&oacute;tulo, nome, log&oacute;tipo, slogan ou outros sinais distintivos pertencentes &agrave; MILUPA;</span></p>
                    <p ><span >6.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Fica expressamente proibido que a Web Terceira contenha informa&ccedil;&otilde;es ou conte&uacute;dos ilegais, contr&aacute;rios &agrave; moral e &agrave; ordem p&uacute;blica, ou que possa infringir os direitos de terceiros.</span></p>
                    <p ><span >7.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Encontra-se ainda expressamente proibida a utiliza&ccedil;&atilde;o de quaisquer marcas, nomes comerciais, sinais, slogans, e outros sinais distintivos pertencentes &agrave; MILUPA sem a permiss&atilde;o pr&eacute;via, expressa e escrita da MILUPA para o efeito.<br />&nbsp;</span></p>
                    <p ><span >AVISO LEGAL SOBRE DADOS PESSOAIS.</span></p>
                    <p ><span >Esta p&aacute;gina web pode tratar os dados pessoais dos utilizadores que a visitem. Sempre que sejam recolhidos dados pessoais estar&aacute; dispon&iacute;vel a respetiva Pol&iacute;tica de Privacidade, com toda a informa&ccedil;&atilde;o relativa ao tratamento dos mesmos por parte da MILUPA.</span></p>
                    <p ><span >A MILUPA contratou a <strong>Worldspoon, Lda.</strong>, com sede social na Rua de Pedrou&ccedil;os n.&ordm; 37 B, Arm. 6, 1400-285 Lisboa, com o n&uacute;mero de identifica&ccedil;&atilde;o de pessoa colectiva 509341152, para a cria&ccedil;&atilde;o, implementa&ccedil;&atilde;o, dinamiza&ccedil;&atilde;o, gest&atilde;o, manuten&ccedil;&atilde;o e acompanhamento da presente p&aacute;gina.</span></p>
                    <p ><span >Em caso de d&uacute;vida, ou no caso de desejar exercer qualquer um dos direitos que lhe cabem enquanto titular dos dados, nomeadamente os de informa&ccedil;&atilde;o, acesso, retifica&ccedil;&atilde;o, apagamento, limita&ccedil;&atilde;o e portabilidade, bem como opor-se ao tratamento dos seus dados pessoais, a qualquer momento, pode entrar em contacto connosco, atrav&eacute;s do endere&ccedil;o de correio eletr&oacute;nico&nbsp;</span><a href="mailto:privacy.hcppt@danone.com"><span >privacy.hcppt@danone.com</span></a> <span >&nbsp;</span></p>
                    <p ><span >Adicionalmente, o titular dos dados poder&aacute; apresentar uma reclama&ccedil;&atilde;o junto da Comiss&atilde;o Nacional de Prote&ccedil;&atilde;o de Dados, autoridade nacional competente em mat&eacute;ria de prote&ccedil;&atilde;o de dados, atrav&eacute;s do endere&ccedil;o de correio eletr&oacute;nico&nbsp;</span><a href="mailto:geral@cnpd.pt"><span >geral@cnpd.pt</span></a></p>
                    <p ><span >ACESSO &Agrave; WEB E SENHAS</span></p>
                    <p ><span >Na Internet n&atilde;o existe seguran&ccedil;a absoluta, mas a MILUPA adopta as medidas t&eacute;cnicas e corporativas necess&aacute;rias, para garantir a prote&ccedil;&atilde;o dos dados de car&aacute;cter pessoal e prevenir a altera&ccedil;&atilde;o, perda, tratamento e ou acesso n&atilde;o autorizado, em conformidade com as disposi&ccedil;&otilde;es legais aplic&aacute;veis.</span></p>
                </div>
            </div>
        );
    }
}
