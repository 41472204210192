import React, { Component } from 'react';

import {Layout, Col, Card, Row, Tooltip} from 'antd';
import Ellipsis from 'react-ellipsis-pjs';
import {FacebookFilled, TwitterSquareFilled, LinkedinFilled} from '@ant-design/icons';
import './index.scss';
import Core from "../../Core.js";
const { Meta } = Card;

export default class Speakers extends Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: []
        };
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.setState({ loading: true });
        Core.apiData(`speakers/list.json`, {
              onSuccess: (data) => {
                  if (data.json) {
                      this.setState({
                        data: data.json,
                      });
                  } else {
                      this.setState({ error: true });
                      console.log("Speakers data is not a JSON!");
                  }
              },
              onFail: (data) => {
                  this.setState({ error: true });
                  console.log("Speakers data failed.", data);
              }
          });
          this.setState({ loading: false });
    }

    render() {
/*        return (
            <div className="speakers-container">
                <Layout>
                    <div>
                        <Row>
                            { this.state.data.map(speaker => {
                                if((speaker.highlight && this.props.display) || !this.props.display) {
                                   return(  <Col className="col" lg={6} md={10} sm={16}>
                                                <Card
                                                    className="col-card"
                                                    cover={<div className="col-card-img" style={{backgroundImage: `url(${"/data/speakers/" + speaker.uid + ".jpg"})`}} />}
                                                    >
                                                    <h4 className="h4-name">{speaker.name}</h4>
                                                    <h5 className="h5-description">{speaker.description}</h5>
                                                </Card>
                                            </Col> );
                                }
                                return null;
                            })}
                        </Row>
                    </div>
                </Layout>
            </div>
            );*/
        const speakers = [];
        const bigSpeakers = [];

        for (const speaker of this.state.data) {
            speakers.push(
                <li id = {speaker.name} className="speaker__container">
                  <div className="speaker">
                    <div className="speaker__photo">
                      <div style={{backgroundImage: `url(${"/data/speakers/" + speaker.uid + ".jpg"})`}}></div>
                    </div>
                    <h3 className="speaker__name">{speaker.name}</h3>
                    <p className="speaker__description">{speaker.description}</p>
                  </div>
                </li>
            );
            bigSpeakers.push(
              <Col xs={24} sm={24} md={12} lg={6} xl={6} id={speaker.name} className="big-speaker" style={{backgroundImage: `url(${"/data/speakers/" + speaker.uid + ".jpg"})` }} >
                  <div className="big-speaker__content">
                    <h3 className="name">{speaker.name}</h3>
                  </div>

              </Col>
            )


        }
        return (
            <div className="speakers-container">
              <Row className="big-speakers">
                { bigSpeakers }
              </Row>
              {/* <ul className="speakers">
                 { speakers }
               </ul> */}

            </div>
        );
    }
}
