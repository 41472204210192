import React, { Component } from "react";
import Linkify from 'react-linkify';
import { getSocket } from '../../../../socket';

import Core from '../../../../Core';

import "./index.scss";

export default class Queue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queue: []
        };
        this.container = React.createRef();
    }

    componentDidMount() {
      this.loadData();
      const that = this;
      getSocket().on('refreshQuestionBox2', function () {
          that.loadData();
      });    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.update();
    }

    update() {
        const container = this.container.current;
        container.scrollTop = container.scrollHeight;
    }

    loadData() {
        Core.apiData(`question-box/${Core.data().arena2}.json`, {
            onSuccess: (data) => {
                if (data.json) {
                    let hasChange = false;
                    for (const item of data.json) {
                        let found = false;
                        for (const question of this.state.queue) {
                            if (item.uid == question.uid) {
                                found = true;
                                break;
                            }
                        }
                        if (found == false) {
                            hasChange = true;
                            break;
                        }
                    }
                    for (const question of this.state.queue) {
                        let found = false;
                        for (const item of data.json) {
                            if (item.uid == question.uid) {
                                found = true;
                                break;
                            }
                        }
                        if (found == false) {
                            hasChange = true;
                            break;
                        }
                    }
                    if (hasChange) {
                        this.setState({ queue: data.json });
                    }
                }  else {
                    console.log("Question Box - Fail to fetch, data is not a json");
                }
            },
            onFail: (data) => {
              console.log("Question Box - Queue", data);
            }
        });
    }

    render() {
        const divs = [];
        const { queue } = this.state;
        for (const question of queue) {
            divs.push(
                <div>
                  <div className="question-box-queue__listitem">
                    <div className="genericPfp"></div>
                    <p className="question-box-queue__paragraph">
                      <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                  <a target="_blank" href={decoratedHref} key={key}>
                                      {decoratedText}
                                  </a>
                              )}
                      >
                          { question.question }
                      </Linkify>
                    </p>
                  </div>
                </div>
            );
        }
        if (divs.length == 0) {
            divs.push(
                <div>
                  <div className="question-box-queue__listitem">
                    <p className="question-box-queue__paragraph">As suas questões serão enviadas para o moderador.</p>
                  </div>
                </div>
            );
        }
        return (
            <div ref={this.container} className="question-box-queue">
              { divs }
            </div>
        );
    }
}
