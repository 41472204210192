import React, { Component } from "react";

import { Modal, Button, Form, Input, notification } from 'antd';

import ReactGA from 'react-ga';

import Core from "../../../Core";

export default class RecoverModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            loading: false
        };
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleFinish = this.handleFinish.bind(this);
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleSubmit() {
        this.form.current.validateFields()
            .then(values => {
                this.form.current.resetFields();
                this.handleFinish(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    handleFinish(values) {
        this.setState({ loading: true });
        const fail = () => {
            notification.error({
                message: 'Recuperar o Aceso',
                description: 'Houve uma falha ao tentar recuperar o acesso, tente mais tarde.',
                top: 200
            });
            ReactGA.event({
                category: Core.data().config.site_title,
                action: `Recovery`,
                label: `Unsuccessful`
              });
        };
        Core.apiService(`recovery`, {
            data: {
                mail: values.mail,
            },
            onSuccess: (data) => {
                if (data.json && data.json.result) {
                    this.setState({ visible: false, loading: false });
                    notification.success({
                        message: 'Recuperar o Acesso',
                        description: 'Foi enviado novamente o mail com os dados de acesso.'
                    });
                    ReactGA.event({
                        category: Core.data().config.site_title,
                        action: `Recovery`,
                        label: `${values.mail})`
                      });
                } else {
                    console.log("Recovery data is not a JSON!");
                    fail();
                }
            },
            onFail: (data) => {
                this.setState({ loading: false, visible: false });
                console.log("Recovery data failed.", data);
                fail();
            }
        });
    };

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    }

    handleCancel() {
        this.setState({ visible: false });
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const { visible, loading } = this.state;

        return (
            <Modal
                title="Recuperar o Acesso"
                visible={visible}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancelar
                    </Button>,
                    <Button key="send" type="primary" htmlType="submit" loading={loading} onClick={this.handleSubmit} >
                        Enviar
                    </Button>
                ]}
            >
                <div key="recover">
                  <Form
                    ref={this.form}
                    name="basic"
                    onFinishFailed={this.onFinishFailed}
                  >
                        <Form.Item
                            label="Endereço de Mail"
                            name="mail"
                            rules={[{ required: true, type: 'email', message: 'Por favor insira o seu endereço de email.' }]}
                        >
                            <Input placeholder="o seu endereço de mail." />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        );
    }
}
