import React, { Component } from "react";
import ReactDOM from "react-dom";
//import { createBrowserHistory } from 'history';
//import ReactGA from 'react-ga';
//import history from "./history";

import { Layout, Menu, Spin, Modal, Button } from 'antd';
import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';
import classNames from 'classnames';

import MainFooter from "./containers/Footer";
import { Redirect } from 'react-router-dom';

import MainPage from './pages/Main';
import TermsConditionPage from './pages/TermsCondition';
import PrivacyPoliciesPage from './pages/PrivacyPolicies';
import CookiesPoliciesPage from './pages/CookiesPolicies';
import SpeakersPage from './pages/Speakers';
import PlayerPage from './pages/Player';
import PlayerPage2 from './pages/Player2';
import RegisterPage from './pages/Register';
import SchedulePage from './pages/Schedule';
import LoginPage from './pages/Login';

import Cookies from './containers/Cookies';

import Core from './Core';
import { getSocket, initSocket } from './socket';

import GA from './components/Utils/GoogleAnalytics'

import './App.scss';

import { UserOutlined } from '@ant-design/icons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      loginLogoutTick: 0,
      defaultSelectedMenu: 'main',
      burgerMenu: false,
      width: 0,
      height: 0,
      isHealthProfessional: sessionStorage.getItem('is-health-professional')
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.handleLogged = this.handleLogged.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleOk = this.handleOk.bind(this);
    Core.onLogged = this.handleLogged;
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (window.location.pathname == '/') {
      this.setState({ defaultSelectedMenu: 'main' });
    } else {
      this.setState({ defaultSelectedMenu: 'none' });
    }
    if (Core.data().login && getSocket() == null) {
      initSocket(Core.data().login);
    }
    Core.menu.click = this.onMenuClick;
    this.loadConfig();
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log(prevState.location);
    //console.log(this.state.location);
    //console.log(history.location.pathname.toUpperCase());
    console.log(window.location.pathname.toUpperCase());
    //if(this.props.location !== prevProps.location ) {
    //    this.setState({ location: window.location.pathname.toUpperCase() })
    //  }
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    Core.menu.click = () => { };
  }


  updateWindowDimensions() {
    const { burgerMenu } = this.state;
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      burgerMenu: window.innerWidth > 800 ? false : burgerMenu
    });
  }

  onMenuClick(selectedMenu) {
    let { defaultSelectedMenu } = this.state;
    if (selectedMenu) {
      defaultSelectedMenu = selectedMenu;
    }
    this.setState({ burgerMenu: false, defaultSelectedMenu });
    //history.push(selectedMenu)
    window.scrollTo(0, 0);
  }


  loadConfig() {
    Core.apiData(`config.json`, {
      onSuccess: (data) => {
        if (data.json) {
          Core.data().config = data.json;
          document.title = data.json.site_title;
          this.setState({ loaded: true });
        } else {
          this.setState({ loaded: false });
          console.log("Config data is not a JSON!", data);
        }
      },
      onFail: (data) => {
        this.setState({ loaded: false });
        console.log("Config data failed!", data);
      }
    });
  }

  handleLogged() {
    const { loginLogoutTick } = this.state;
    this.setState({ loginLogoutTick: loginLogoutTick + 1 });

  }

  handleLogout() {
    const { loginLogoutTick } = this.state;
    Core.data().login = null;
    sessionStorage.clear();
    this.setState({ loginLogoutTick: loginLogoutTick + 1 });
    window.location.assign("/login")

  }

  handleOk() {
    sessionStorage.setItem('is-health-professional', '1');
    this.setState({ isHealthProfessional: '1' });
  };

  render() {
    const { burgerMenu, defaultSelectedMenu, loaded, logout, isHealthProfessional } = this.state;
    if (loaded == false) {
      return (
        <Layout>
          <div className="loading">
            <Spin size="large" />
            <h1>A carregar...</h1>
          </div>
        </Layout>
      );
    }

    return (
      <Router>
        { GA.init() && <GA.RouteTracker />}
        <div className="page">
          <Layout>
            {(!Core.data().config.private || window.location.pathname.toUpperCase() !== '/LOGIN') ?
              <Header className={classNames({ 'header-burger-open': burgerMenu })}>
                <h1 className="header-title">
                  <Link to="/" onClick={() => this.onMenuClick('main')}>
                    <img src="/data/images/logo.png" />
                  </Link>
                </h1>
                <div className="header-burger">
                  <Burger isOpen={burgerMenu} onClick={() => { this.setState({ burgerMenu: !burgerMenu }); }} />
                </div>
                <div className={
                  classNames({
                    'menu': true,
                    'menu-burger-open': burgerMenu
                  })
                }>
                  <Menu
                    theme="dark"
                    mode={burgerMenu ? 'vertical' : 'horizontal'}
                    defaultSelectedKeys={[defaultSelectedMenu]}
                    selectedKeys={[defaultSelectedMenu]}>
                    {Core.data().config.no_home == false ?
                      <Menu.Item key="main"><Link to="/" onClick={() => this.onMenuClick('main')}>Home</Link></Menu.Item>
                      : null}
                    {Core.data().config.speakers == true ?
                      <Menu.Item key="speakers"><Link to="/speakers" onClick={() => this.onMenuClick('speakers')}>Oradores</Link></Menu.Item>
                      : null}
                    {Core.data().config.schedules == true ?
                      <Menu.Item key="programa"><Link to="/programa" onClick={() => this.onMenuClick('programa')}>Agenda</Link></Menu.Item>
                      : null}
                    {(Core.data().config.login == true && Core.data().user_arena == Core.data().arena
                      && Core.data().login && Core.data().arena && Core.data().user_arena)
                      && Core.data().config.no_home == false ?
                      <Menu.Item key="player" className="player-menu" ><Link to="/player" onClick={() => this.onMenuClick('player')}>Evento</Link></Menu.Item> : null}
                    {Core.data().login ? null : <Menu.Item className="player-menu" ><Link to="/login">Evento</Link></Menu.Item>}
                    {(Core.data().config.login == true && Core.data().user_arena == Core.data().arena2
                      && Core.data().login && Core.data().arena2 && Core.data().user_arena)
                      && Core.data().config.no_home == false ?
                      <Menu.Item key="player2" className="player-menu" ><Link to="/player2" onClick={() => this.onMenuClick('player2')}>Evento2</Link></Menu.Item> : null}
                    {Core.data().config.login == true || Core.data().config.register == true ?
                      <SubMenu className="right-input profile" title={<UserOutlined className="site-form-item-icon" />}>
                        {Core.data().config.login == true ? (
                          Core.data().login ?
                            <Menu.Item key="logout"><a onClick={this.handleLogout}>Logout</a></Menu.Item>
                            : <Menu.Item key="login"><Link to="/login" onClick={() => this.onMenuClick('login')}>Login</Link></Menu.Item>
                        ) : null}
                        {Core.data().config.register == true && Core.data().config.email_only == false ?
                          <Menu.Item key="registo"><Link to="/registo" onClick={() => this.onMenuClick('registo')}>Registo</Link></Menu.Item>
                          : null}
                      </SubMenu>
                      : null}
                    <Menu.ItemGroup title="Perfil" className="profile-mobile">
                      {Core.data().login ?
                        <Menu.Item key="logout"><a onClick={this.handleLogout}>Logout</a></Menu.Item>
                        : <Menu.Item key="login"><Link to="/login" onClick={() => this.onMenuClick('login')}>Login</Link></Menu.Item>}
                      {Core.data().config.register == true ?
                        <Menu.Item key="registo"><Link to="/registo" onClick={() => this.onMenuClick('registo')}>Registo</Link></Menu.Item>
                        : null}
                    </Menu.ItemGroup>
                  </Menu>
                </div>
              </Header>
              : null}
            <Content>
              <Switch>
                <Route path="/termos-e-condicoes">
                  <TermsConditionPage />
                </Route>
                <Route path="/politica-de-privacidade">
                  <PrivacyPoliciesPage />
                </Route>
                <Route path="/politica-de-cookies">
                  <CookiesPoliciesPage />
                </Route>
                {Core.data().config.speakers == true ?
                  <Route path="/speakers">
                    <SpeakersPage />
                  </Route>
                  : null}
                <Route path="/player">
                  <PlayerPage />
                </Route>
                <Route path="/player2">
                  <PlayerPage2 />
                </Route>
                {Core.data().config.register == true ?
                  <Route path="/registo">
                    <RegisterPage />
                  </Route>
                  : null}
                {Core.data().config.schedules == true ?
                  <Route path="/programa">
                    <SchedulePage />
                  </Route>
                  : null}
                <Route path="/login/:token?" component={LoginPage} />
                <Route path="/">
                  <MainPage />
                </Route>
              </Switch>
            </Content>
            {(!Core.data().config.private || window.location.pathname.toUpperCase() !== '/LOGIN') ?
              <MainFooter />
              : null}
          </Layout>
        </div>
        {/* <Cookies /> */}
        { isHealthProfessional !== '1' &&
          <Modal
            className="is-health-professional-modal"
            closable={false}
            visible={true}
            centered={true}
            footer={[
              <Button key="submit" type="primary" id="confirmation-button-ok" onClick={this.handleOk}>
                CONFIRMO QUE SOU PROFISSIONAL DE SAÚDE
            </Button>,
              <Button key="back" onClick={() => { window.location.replace("http://www.google.com") }}>
                SAIR
            </Button>
            ]}
          >
            <h1>É Profissional de Saúde?</h1>
            <p>
              Esta página é exclusivamente destinada a profissionais de saúde.
              <br />
              Por favor confirme que é um/uma profissional de saúde.
            </p>
          </Modal>
        }
      </Router>
    );
  }
}
