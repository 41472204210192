import React, { Component } from 'react';
import { Layout } from 'antd';
import Register from '../../containers/Register';

import './index.scss';

export default class RegisterPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <Layout className="register-page">
        <img className="nome_evento_logo" src="/data/images/nome_evento_logo.png" />
        <img className="produto" src="/data/images/produto.png" />
        <main>
          <Register />
        </main>
      </Layout>
    );
  }
}
