import React, { Component } from "react";

import { Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import Login from "../../containers/Login";
import Core from "../../Core.js";
import history from "../../history";
import { getSocket, initSocket } from '../../socket';

import './index.scss';

export default class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
        this.button = React.createRef();
        this.handleLoggedIn = this.handleLoggedIn.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleLoggedIn(data) {
        Core.onLogged();
        this.setState({ data });
        if(Core.data().login &&  getSocket() == null) {
          initSocket(Core.data().login);
        }
    }

    render() {
        const { match } = this.props;
        const { params } = match;
        const { data } = this.state;
        let main = null;
        if (data) {
            if (Core.data().config.private == true && Core.data().config.no_home == false) {
                return <Redirect to="/" />;

            } else if (Core.data().user_arena && Core.data().user_arena == Core.data().arena2) {

                return <Redirect to="/player2" />;
            } else {
                return <Redirect to="/player" />;
            }
        }
        return (
            <Layout className="login-page">

                <img className="nome_evento_logo" src="/data/images/nome_evento_logo.png" />
                <img className="produto" src="/data/images/produto.png" />
                <main>
                    <Login token={params.token} onLoggedIn={this.handleLoggedIn} />
                </main>
            </Layout>
        );
    }
}
