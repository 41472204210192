
import { io } from "socket.io-client";
import config from './config';
import Core from './Core';

//WebSocket Configs
const socketConfig = {
    path: "/connect",
    transports: ['websocket'],  // https://stackoverflow.com/a/52180905/8987128
    allowUpgrades: false,
    query: {},
    reconnect: false,
    //secure: true,
    rejectUnauthorized: false
}

let socketIOConn = null;

const getSocket = () =>  {
  return socketIOConn;
}

const initSocket = (user) =>  {
  if(socketIOConn == null) {
    const queryParams = { userType: "fo", userUid: user.uid, userName: user.name };
    socketConfig.query = queryParams;
    socketIOConn = io(config.api.webSocketServer, socketConfig);

    socketIOConn.on("kicked", sockedId => {
        Core.data().login = null;
        sessionStorage.clear();
        window.location.assign("/login")
    });
  }

}

export  { getSocket, initSocket };
