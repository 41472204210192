import React, { Component} from "react";
import Linkify from 'react-linkify';

import Core from '../../../../Core';

import "./index.scss";
import Button from "antd/lib/button";
import {DownOutlined} from "@ant-design/icons";

export default class Queue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queue: [],
            messages: [],
            visible: false,
            firstAutoScroll: false,
            scrollHeight: 0,
            secondZero: false
        };
        this.autoScroll = true;
        this.container = React.createRef();
        this.tail = React.createRef();
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.refresh = true;

    }

    componentDidMount() {
        this.loadData();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.update(prevState);
    }

    update(prevState) {
        const { queue } = this.state;

        const container = this.container.current;
        if (this.autoScroll == true) {
            container.scrollTop = container.scrollHeight;
            this.autoScroll = false;
            
            this.setState({ scrollHeight: container.scrollTop })
        }
    }

    loadData() {
        const delay = 2000;
        const fail = () => {
            window.setTimeout(() => this.loadData(), delay);
        };
        if(this.refresh){
            Core.apiData(`chat/${Core.data().arena}.json`, {
                onSuccess: (data) => {
                    if (data.json) {
                        if (!this.container.current
                            || (this.container.current
                                && this.container.current.scrollTop > this.container.current.scrollHeight - 10)
                            || !this.state.firstAutoScroll
                            || (this.container.current.scrollTop < this.state.scrollHeight - 10 && this.container.current.scrollTop > this.state.scrollHeight - 50)
                            || this.container.current.scrollTop == this.state.scrollHeight
                        ) {
                            this.autoScroll = true;
                        } else {
                            this.autoScroll = false;
                        }

                        let hasChange = false;
                        for (const item of data.json) {
                            let found = false;
                            for (const chat of this.state.queue) {
                                if (item.uid == chat.uid) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found == false) {
                                hasChange = true;
                                break;
                            }
                        }
                        for (const chat of this.state.queue) {
                            let found = false;
                            for (const item of data.json) {
                                if (item.uid == chat.uid) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found == false) {
                                hasChange = true;
                                break;
                            }
                        }
                        if (hasChange && this.refresh) {
                            this.setState({ queue: data.json, firstAutoScroll: true });
                        }
                        window.setTimeout(() => this.loadData(), delay);
                    } else {
                        fail();
                    }
                },
                onFail: (data) => {
                    console.log("Chat Box - Queue", data);
                    fail();
                }
            });
        }

    }

    updateChat(){
        this.refresh = true;
        this.loadData();
    }

    newMessage(message) {
        this.autoScroll = true;
        this.refresh = false;
        const date = new Date();
        const hour = date.getHours() > 10 ? date.getHours() : '0'+ date.getHours();
        const minutes = date.getMinutes() > 10 ? date.getMinutes() : '0'+ date.getMinutes();
        const photo = Core.data().login.photo ? `url(/data/chat/${Core.data().login.hid}.jpg`: `url(/images/chat-generic-photo.svg)`;
        const tmpMessage = {
            message: message,
            moment: `${hour}:${minutes}`,
            participant:
                {
                    name: Core.data().login.name,
                    photo: photo,
                    hid: Core.data().login.hid,
                    uid: Core.data().login.uid
                }
        };

        let queue = this.state.queue;
        queue.push(tmpMessage);
        this.setState({ queue: queue });
    }


    scrollToBottom() {
        const node = this.container.current; //get the element via ref
        if (node) {
            node.scroll({top: node.scrollHeight,  behavior: 'smooth' });
            window.setTimeout(() => this.setState({ visible: false, secondZero: false}), 500);
        }
    };

    // Hide or show the button.
    handleScroll(event) {
        const target = event.target;
        const size = target.scrollHeight - target.scrollTop;
        //console.log(size);

        if(size > target.clientHeight) {
            if(!this.state.visible) {
                this.setState({ visible: true });
            }
        } else if (size === target.clientHeight && this.state.visible) {
            //if(!this.state.secondZero){
            //    this.setState({ secondZero: true })
            //} else {
                this.setState({ visible: false, secondZero: false})
            //}
        }
    };

    render() {
        const divs = [];
        const msgsInTheBox = [];
        const { queue, messages } = this.state;

        for (const chat of queue) {

            const mine = chat.participant.hid == Core.data().login.hid;
            let photo = null;
            if (chat.participant.photo) {
                photo = <div className="chat-box-queue__photo" style={{ backgroundImage: `url(/data/chat/${chat.participant.hid}.jpg)`}}></div>;
            } else {
                photo = <div className="chat-box-queue__photo" style={{ backgroundImage: `url(/images/chat-generic-photo.svg)`}}></div>;
            }
            if(msgsInTheBox.indexOf(chat.uid) === -1){
                divs.push(
                    <div>
                        <div className={ 'chat-box-queue__listitem'+ (mine ? ' chat-box-queue__mine' : '')}>
                            { photo }
                            <div className="chat-box-queue__info">
                                <span className="chat-box-queue__moment">{chat.moment.substring(11, 16)}</span>
                                <span className="chat-box-queue__name">{chat.participant.name}</span>
                            </div>
                            <div className="chat-box-queue__message">
                                <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                            <a target="_blank" href={decoratedHref} key={key}>
                                                {decoratedText}
                                            </a>
                                        )}
                                >
                                    {chat.message}
                                </Linkify>
                            </div>
                        </div>
                    </div>
                );

                msgsInTheBox.push(chat.uid);
            }
        }

        if (divs.length == 0) {
            divs.push(
                <div>
                    <div className="chat-box-queue__listitem">
                      <div className="chat-box-queue__message">Não há nenhuma mensagem...</div>
                    </div>
                </div>
            );
        }

        return (
            <>
                <div ref={this.container} onScroll={this.handleScroll} className="chat-box-queue">
                    {divs}
                    <div ref={this.tail}></div>
                </div>
                <div className={this.state.visible ? null : "chat-box__following" }>
                    <Button className="chat-box__tail" icon={<DownOutlined />}  onClick={this.scrollToBottom} />
                </div>
            </>
        );
    }
}
